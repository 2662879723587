@use '~@amzn/awsui-design-tokens/polaris' as awsui;

.input-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  order: 0;
  flex-grow: 10;
  margin-right: 0;
  margin-bottom: calc(-1 * #{awsui.$space-scaled-m});

  > *:not(:empty) {
    margin-right: awsui.$space-scaled-m;
    margin-bottom: awsui.$space-scaled-s;
  }
}

.input-filter {
  order: 0;
  flex-grow: 6;
  width: auto;
  max-width: 728px;
}

.select-filter {
  max-width: 130px;
  flex-grow: 2;
  width: auto;
}

.filtering-results {
  margin-left: 1rem;
  line-height: 3rem;
  color: awsui.$color-text-form-default;
}

@media (max-width: 1152px) {
  .input-container {
    margin-right: calc(-1 * #{awsui.$space-scaled-m});
  }

  .select-filter {
    max-width: none;
  }

  .input-filter {
    width: 100%;
    max-width: none;
  }
}
