/*Header*/
.groupheader {
  font-weight: 800;
}

.grayHeader {
  background-color: gray;
  color: white;
}

.blackHeader {
  background-color: black;
  color: white;
}

/*Rows*/

.importantRow {
  font-weight: 600;
}

.lightBlue {
  background-color: lightcyan !important;
}

/*Cells*/
.uneditable {
  background-color: #f7f7f7 !important;
}

.modified {
  background-color: rgba(255, 193, 7, 0.5) !important;
}

.warningValue {
  background-color: rgba(249, 251, 42, 0.18) !important;
}

.negativeValue {
  color: red;
}

.error {
  background-color: rgba(245, 183, 177, 0.5) !important;
}

.actual {
  background-color: rgba(131, 210, 223, 0.1) !important;
}

.groupcell1 {
  background-color: rgba(246, 234, 123, 0.05) !important;
}

.groupcell2 {
  background-color: rgba(255, 186, 82, 0.07) !important;
}

.groupcell3 {
  background-color: rgba(230, 131, 169, 0.05) !important;
}

/*Others*/
.percentage {
  display: inline-block;
  position: relative;
}

.percentage::after {
  position: absolute;
  top: 3px;
  right: 0px;
  transition: all 0.05s ease-in-out;
  content: '%';
}

.percentage:hover::after,
.percentage:focus-within::after {
  right: 10px;
}

.custom-tooltip {
  width: auto;
  height: 35px;
  border: 1px solid cornflowerblue;
  overflow: hidden;
  position: absolute;
  background-color: rgba(245, 183, 177, 0.9);
  font-family: 'Amazon Ember', 'Helvetica Neue', Roboto, Arial, sans-serif;
}

.custom-tooltip p {
  margin: 10px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}

.status-help-panel-container {
  z-index: 1000;
  position: absolute;
  top: 0;
  right: -3%;
  height: 100%;
  width: 500px;
}

.close-icon-container-help-panel {
  float: right;
}

.active-status-description {
  /* Color recommendations for active components are found in
   https://v2-1.polaris.a2z.com/fundamentals/foundation/colors/*/
  border-color: #00a1c9;
  border-width: 2px;
  border-style: solid;
  background-color: #f1faff;
  padding: 0 10px;
  margin-right: 10px;
}

.inactive-status-description {
  padding: 0 10px;
}
